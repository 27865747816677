import { map, Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { Vendor, VendorResponse } from '@shared/models';

import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class VendorsService {
  private apiService = inject(ApiService);

  getVendors(): Observable<Vendor[]> {
    return this.apiService.get<VendorResponse>('vendors').pipe(map((response) => response.vendors));
  }
}
