import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { DataResolverService } from './resolver/data-resolver.service';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/initial', pathMatch: 'full' },
  { path: 'initial', loadChildren: () => import('./initial/initial.module').then((m) => m.InitialPageModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then((m) => m.LoginPageModule) },
  {
    path: 'tecservice',
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tecservice-transportec',
    loadChildren: () => import('./tabs-transportec/tabs-transportec.module').then((m) => m.TabsTransportecPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tecservice-driver',
    loadChildren: () => import('./tabs-driver/tabs-driver.module').then((m) => m.TabsDriverPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'preregister',
    loadChildren: () => import('./preregister/preregister.module').then((m) => m.PreregisterPageModule),
  },
  {
    path: 'register',
    children: [
      {
        path: '',
        loadChildren: () => import('./register/register.module').then((m) => m.RegisterPageModule),
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            resolve: {
              special: DataResolverService,
            },
            loadChildren: () => import('./register/register.module').then((m) => m.RegisterPageModule),
          },
        ],
      },
    ],
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then((m) => m.ForgotPasswordPageModule),
  },
  {
    path: 'email-confirmation',
    loadChildren: () =>
      import('./email-confirmation/email-confirmation.module').then((m) => m.EmailConfirmationPageModule),
  },
  { path: 'scan', loadChildren: () => import('./scan/scan.module').then((m) => m.ScanPageModule) },
  {
    path: 'payment-card',
    loadChildren: () =>
      import('./tabs-transportec/wallet/payment-card/payment-card.module').then((m) => m.PaymentCardPageModule),
  },
  {
    path: 'subscribe',
    loadChildren: () => import('./tabs-transportec/subscribe/subscribe.module').then((m) => m.SubscribePageModule),
  },
  {
    path: '**',
    redirectTo: '/initial',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
