export function getMexicanDatetime(datetime: Date | string = new Date(), locale = 'en-US'): string {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'America/Mexico_city',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  const formatter = new Intl.DateTimeFormat(locale, options);

  return formatter.format(new Date(datetime));
}

export function getMexicanSqlDate(datetime: Date | string) {
  const nowInMexico = getMexicanDatetime(datetime, 'en-US');
  const nowInMexicoArray = nowInMexico.substr(0, 10).split('/');
  return nowInMexicoArray[2] + '-' + nowInMexicoArray[0] + '-' + nowInMexicoArray[1];
}

export function formatSqlDate(datetime: Date | string | number, withTime = false) {
  const date = new Date(datetime);
  const year = date.getFullYear();

  let month = '' + (date.getMonth() + 1),
    day = '' + date.getDate();
  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }
  const time = withTime ? ' ' + date.toTimeString().split(' ')[0] : '';
  return [year, month, day].join('-') + time;
}
